import React, { useState, useEffect } from 'react';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  CircularProgress,
  Box
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@mui/icons-material/Close';
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore';
import { db } from '../firebaseConfig';

const WaitlistModal = ({ isOpen, onClose, weekStart, day, timeOfDay, formatDate }) => {
  const [waitlistUsers, setWaitlistUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWaitlistUsers = async () => {
      if (!isOpen) return;
      
      try {
        // Query the waitlist collection
        const waitlistRef = collection(db, 'waitlist');
        const waitlistQuery = query(
          waitlistRef,
          where('weekStart', '==', weekStart),
          where('day', '==', day),
          where('timeOfDay', '==', timeOfDay)
        );
        
        const waitlistSnapshot = await getDocs(waitlistQuery);
        
        // Fetch user details for each waitlist entry
        const userDetailsPromises = waitlistSnapshot.docs.map(async (waitlistDoc) => {
          const waitlistData = waitlistDoc.data();
          const userRef = doc(db, 'users', waitlistData.userId);
          const userDoc = await getDoc(userRef);
          
          return {
            waitlistId: waitlistDoc.id,
            userId: waitlistData.userId,
            createdAt: waitlistData.createdAt?.toDate(),
            userName: userDoc.exists() ? userDoc.data().userName || 'Unknown User' : 'Unknown User'
          };
        });

        const waitlistWithUserDetails = await Promise.all(userDetailsPromises);
        
        // Sort by creation date if available
        const sortedWaitlist = waitlistWithUserDetails.sort((a, b) => 
          (a.createdAt && b.createdAt) ? a.createdAt - b.createdAt : 0
        );

        setWaitlistUsers(sortedWaitlist);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching waitlist:', error);
        setWaitlistUsers([]);
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchWaitlistUsers();
    }
  }, [isOpen, weekStart, day, timeOfDay]);

  return (
    <Dialog 
      open={isOpen} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ 
        m: 0, 
        p: 2,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography variant="h6">
          Waitlist for {formatDate(weekStart, day)} ({timeOfDay.replace("afternoon", "PM").replace("morning", "AM")})
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : waitlistUsers.length > 0 ? (
          <List>
            {waitlistUsers.map((user) => (
              <ListItem key={user.waitlistId}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText 
                  primary={user.userName}
                  secondary={user.createdAt ? user.createdAt.toLocaleString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true
                  }) : null}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Box sx={{ textAlign: 'center', p: 2 }}>
            <Typography color="textSecondary">
              No users on the waitlist
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default WaitlistModal;