import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Paper,
  Button,
  Grid,
  TextField,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import DownloadIcon from '@mui/icons-material/Download';
import ClearIcon from '@mui/icons-material/Clear';

const ReleaseReport = () => {
  const [releases, setReleases] = useState([]);
  const [filteredReleases, setFilteredReleases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [selectedMember, setSelectedMember] = useState('');
  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    fetchReleases();
  }, []);

  useEffect(() => {
    filterReleases();
  }, [releases, startDate, endDate, selectedMember]);

  useEffect(() => {
    // Extract unique members from releases
    const uniqueMembers = [...new Set(releases.map(release => 
      (release.memberName || '').trim()
    ))]
      .filter(Boolean) // Remove any empty strings
      .sort((a, b) => a.localeCompare(b));
    setMemberList(uniqueMembers);
  }, [releases]);

  const fetchReleases = async () => {
    try {
      setLoading(true);
      
      const membersQuery = query(
        collection(db, 'members'),
        where('status', '==', 'Active')
      );
      
      const querySnapshot = await getDocs(membersQuery);
      const releasedSeats = [];
      
      querySnapshot.forEach((doc) => {
        const member = { id: doc.id, ...doc.data() };
        
        if (!member.customSchedules) return;
        
        member.customSchedules.forEach(customSchedule => {
          ['0', '1', '2', '3', '4'].forEach(day => {
            ['morning', 'afternoon'].forEach(timeSlot => {
              const defaultAttending = member.defaultSchedule[day]?.[timeSlot];
              const customAttending = customSchedule.schedule[day]?.[timeSlot];
              
              if (defaultAttending && !customAttending) {
                const weekStart = new Date(customSchedule.weekStart);
                const releaseDate = new Date(weekStart);
                releaseDate.setDate(releaseDate.getDate() + parseInt(day) + 1);
                
                releasedSeats.push({
                  id: `${member.id}-${day}-${timeSlot}`,
                  memberName: member.childName,
                  userId: member.userId,
                  weekStart: customSchedule.weekStart,
                  day: getDayName(parseInt(day)),
                  timeOfDay: timeSlot,
                  releaseDate: releaseDate,
                  releaseDateFormatted: formatDate(releaseDate, false),
                  updatedDate: member.updatedDate?.toDate() || new Date(),
                  updatedDateFormatted: formatDate(member.updatedDate?.toDate() || new Date(), true)
                });
              }
            });
          });
        });
      });
      
      // Sort by release date descending
      releasedSeats.sort((a, b) => b.releaseDate - a.releaseDate);
      
      setReleases(releasedSeats);
      setFilteredReleases(releasedSeats);
    } catch (err) {
      console.error("Error fetching releases:", err);
      setError("Failed to load release data");
    } finally {
      setLoading(false);
    }
  };

  const filterReleases = () => {
    let filtered = [...releases];

    if (startDate) {
      const startDateTime = new Date(startDate);
      startDateTime.setHours(0, 0, 0, 0);
      filtered = filtered.filter(release => release.releaseDate >= startDateTime);
    }

    if (endDate) {
      const endDateTime = new Date(endDate);
      endDateTime.setHours(23, 59, 59, 999);
      filtered = filtered.filter(release => release.releaseDate <= endDateTime);
    }

    if (selectedMember) {
      filtered = filtered.filter(release => {
        // Ensure we're comparing trimmed strings to avoid whitespace issues
        const releaseNameTrimmed = (release.memberName || '').trim();
        const selectedNameTrimmed = selectedMember.trim();
        const isMatch = releaseNameTrimmed === selectedNameTrimmed;
        
        // Add console log for debugging in development
        if (process.env.NODE_ENV === 'development') {
          console.log(`Comparing: "${releaseNameTrimmed}" with "${selectedNameTrimmed}": ${isMatch}`);
        }
        
        return isMatch;
      });
    }

    setFilteredReleases(filtered);
    setPage(0); // Reset to first page when filters change
  };

  const handleClearFilters = () => {
    setStartDate('');
    setEndDate('');
    setSelectedMember('');
  };

  const getDayName = (dayIndex) => {
    const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
    return days[dayIndex];
  };

  const formatDate = (date, includeTime = true) => {
    const day = date.toLocaleDateString('en-US', { weekday: 'short' });
    const month = date.toLocaleDateString('en-US', { month: 'short' });
    const dayNum = date.getDate();
    const year = date.getFullYear();
    
    if (!includeTime) {
      return `${day} ${month} ${dayNum} ${year}`;
    }
    
    const time = date.toLocaleTimeString('en-US', { 
      hour: '2-digit', 
      minute: '2-digit',
      hour12: true 
    });
    
    return `${day} ${month} ${dayNum} ${year} ${time}`;
  };

  const downloadCSV = () => {
    const headers = ['Member', 'Day', 'Time of Day', 'Seat Released', 'Released On'];
    
    const csvData = filteredReleases.map(release => [
      release.memberName,
      release.day,
      release.timeOfDay,
      release.releaseDateFormatted,
      release.updatedDateFormatted
    ]);
    
    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.map(cell => `"${cell}"`).join(','))
    ].join('\n');
    
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `released_seats_${formatDate(new Date())}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'flex-start', 
      p: 3,
      minHeight: '100vh',
      bgcolor: '#f5f5f5'
    }}>
      <Card sx={{ maxWidth: 800, width: '100%' }}>
        <CardContent>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 2 
          }}>
            <Typography variant="h6" component="h2">
              Released Seats Report
            </Typography>
            <Button 
              variant="outlined" 
              startIcon={<DownloadIcon />}
              onClick={downloadCSV}
              disabled={filteredReleases.length === 0}
            >
              CSV
            </Button>
          </Box>

          <Grid container spacing={2} sx={{ mb: 3 }}>
          {true == false &&<Grid item xs={12} sm={3}>
            
              <FormControl fullWidth size="small">
                <InputLabel>Member</InputLabel>
                <Select
                  value={selectedMember}
                  label="Member"
                  onChange={(e) => setSelectedMember(e.target.value)}
                >
                  <MenuItem value="">All Members</MenuItem>
                  {memberList.map((member) => (
                    <MenuItem key={member} value={member}>
                      {member}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>}
            <Grid item xs={12} sm={4}>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                fullWidth
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                fullWidth
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                variant="outlined"
                onClick={handleClearFilters}
                startIcon={<ClearIcon />}
                fullWidth
                sx={{ height: '40px' }}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
          
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Member</strong></TableCell>
                  {true == false && <TableCell><strong>Day</strong></TableCell>}
                  <TableCell><strong>Seat Released</strong></TableCell>
                  <TableCell><strong>Time</strong></TableCell>
                  <TableCell><strong>Released On</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredReleases
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((release) => (
                    <TableRow key={release.id}>
                      <TableCell>{release.memberName}</TableCell>
                      {true == false && <TableCell>{release.day}</TableCell>}
                      <TableCell>{release.releaseDateFormatted}</TableCell>
                      <TableCell className="capitalize">{release.timeOfDay}</TableCell>
                      <TableCell>{release.updatedDateFormatted}</TableCell>
                    </TableRow>
                  ))}
                {filteredReleases.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No released seats found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={filteredReleases.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ReleaseReport;